import { useMutation } from "@tanstack/react-query";
import {
  noteDelete,
  NoteDeleteParams,
} from "common/api/diktafont/diktafont_note_edit";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseNoteDeleteParams = {
  invalidateKeys?: string[];
};

export const useNoteDelete = (hookParams?: UseNoteDeleteParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: noteDeleteMutate } = useMutation({
    mutationFn: async (deletePayload: NoteDeleteParams) =>
      await noteDelete(deletePayload),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }
    },
  });

  return { noteDeleteMutate };
};
