import React from "react";
import styled from "@emotion/styled";
import { Button, CardActions } from "@mui/material";
import { useNoteNavigation } from "hooks/navigation/UseNoteNavigation";
import { useNoteDelete } from "hooks/diktafont/note/useNoteDelete";
import { QUERY_KEYS } from "constant/react_query";
import { VIEW_TYPE, VIEW_TYPE_MODE } from "constant/view_type";
import RecognizeNote from "models/RecognizeNote";
import useConfirm from "common/modal/ConfirmDialog";
import { useIndexedDB } from "react-indexed-db";

type NoteSingleNavProps = {
  note: RecognizeNote;
};

function NoteSingleNav({ note }: NoteSingleNavProps) {
  const { noteNavigate } = useNoteNavigation();
  const { noteDeleteMutate } = useNoteDelete({
    invalidateKeys: [QUERY_KEYS.NOTE_LIST_GET],
  });

  const db = useIndexedDB("notes");

  const actionConfirm = useConfirm();

  const editHandle = () => {
    noteNavigate(VIEW_TYPE.SINGLE, note, VIEW_TYPE_MODE.EDIT);
  };

  const deleteHandle = async () => {
    const check = await actionConfirm({
      title: "Text",
      message: "Are you sure you want to delete text",
    });
    if (check) {
      noteDeleteMutate({
        deleteRecord: db.deleteRecord,
        payload: { id: note.id as number },
      });
    }
  };

  return (
    <CardActionsNav>
      <Button onClick={editHandle}>Edit</Button>
      <Button onClick={deleteHandle}>Delete</Button>
    </CardActionsNav>
  );
}

const CardActionsNav = styled(CardActions)`
  justify-content: space-between;
`;

export default NoteSingleNav;
