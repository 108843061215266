import { Key } from "react-indexed-db/lib/indexed-db";

export type NoteAddParams = {
  add: <T = any>(value: T, key?: any) => Promise<number>;
  payload: {
    name: string;
    text: string;
  };
};

export type NoteUpdateParams = {
  update: <T = any>(value: T, key?: any) => Promise<any>;
  payload: {
    id: number;
    name: string;
    text: string;
  };
};

export type NoteDeleteParams = {
  deleteRecord: (key: Key) => Promise<any>;
  payload: {
    id: number;
  };
};

export const noteAdd = async ({
  add,
  payload: { name, text },
}: NoteAddParams): Promise<number> => {
  let result: number = 0;
  try {
    result = await add({
      name,
      text,
      dateTime: new Date(),
    });
  } catch (err) {
    console.error(err);
  }

  return result;
};

export const noteUpdate = async ({
  update,
  payload: { id, name, text },
}: NoteUpdateParams): Promise<number> => {
  let result: number = 0;
  try {
    await update({
      id,
      name,
      text,
      // dateTime: new Date(),
    });
  } catch (err) {
    console.error(err);
  }

  return result;
};

export const noteDelete = async ({
  deleteRecord,
  payload: { id },
}: NoteDeleteParams): Promise<boolean> => {
  let result: boolean = false;
  try {
    await deleteRecord(id);
    result = true;
  } catch (err) {
    console.error(err);
    result = false;
  }

  return result;
};
