/* eslint-disable react/prop-types */
import React from "react";
import { useRecoilValue } from "recoil";
import { Container } from "@mui/system";
import { isSingleViewState } from "store/selectors/view_type";
import NoteSingle from "components/note/NoteSingle";
import NoteList from "components/note/NoteList";

const MainContent = () => {
  const isSingleView = useRecoilValue(isSingleViewState);

  const containterContent = isSingleView ? (
    <NoteSingle />
  ) : (
    <Container
      sx={{
        boxShadow: "2px 2px 2px #888888",
        minHeight: "35rem",
        backgroundColor: "rgba(200, 200, 200, 0.1)",
        paddingTop: "0.8rem",
        paddingBottom: "0.8rem",
        position: "relative",
      }}
    >
      <NoteList />
    </Container>
  );

  const content = containterContent;

  return content;
};

export default MainContent;
