export const VIEW_TYPE_MODE = {
  READ: "read",
  EDIT: "edit",
};

export const VIEW_TYPE = {
  SINGLE: "single",
  LIST: "list",
};

export type ViewType = (typeof VIEW_TYPE)[keyof typeof VIEW_TYPE];

export type ViewTypeMode = (typeof VIEW_TYPE_MODE)[keyof typeof VIEW_TYPE_MODE];
