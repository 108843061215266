import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import styled from "@emotion/styled";
import NavNoteList from "components/navigation/NavNoteList";
import { MOB_MAX_WIDTH } from "constant/constants";
import NavNoteNew from "components/navigation/NavNoteNew";

function MainHeader({ diktafontNavigationActToggle }: any) {
  return (
    <DivMainHeader>
      <h2>DIKTAFONT</h2>
      <div>
        <NavNoteList />
        <NavNoteNew />
      </div>
      <DivMainMenuNav>
        <IconButton aria-label="Example" onClick={diktafontNavigationActToggle}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </IconButton>
      </DivMainMenuNav>
    </DivMainHeader>
  );
}

const DivMainMenuNav = styled.div`
  display: none;
  @media (max-width: ${MOB_MAX_WIDTH}) {
    display: inline;
  }
`;

const DivMainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export default MainHeader;
