import { atom } from "recoil";
import RecognizeNote from "../../models/RecognizeNote";
import { VIEW_TYPE, VIEW_TYPE_MODE } from "constant/view_type";

export const viewTypeState = atom({
  key: "viewTypeState",
  default: {
    type: VIEW_TYPE.SINGLE,
    note: new RecognizeNote(),
    mode: VIEW_TYPE_MODE.EDIT,
  },
});
