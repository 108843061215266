import { obDefaultValGet } from "../common/common";

export default class RecognizeNote {
  public id: string | number;
  public name: string;
  public text: string;
  public dateTime: Date;

  constructor(item: any = {}) {
    this.id = obDefaultValGet(item, "id", "");
    this.name = obDefaultValGet(item, "name", "");
    this.text = obDefaultValGet(item, "text", "");
    const dateTimeS = obDefaultValGet(item, "dateTime", new Date().toString());
    this.dateTime = new Date(dateTimeS);
  }

  static async getAll(db: any) {
    let items = await db.getAll();
    items = items.map((item: any) => new RecognizeNote(item));
    return items;
  }

  static async getByID(db: any, id: string) {
    let res = await db.getByID(id);
    res = new RecognizeNote(res);
    return res;
  }

  static async getMaxID(db: any) {
    const notes = await this.getAll(db);
    const res = notes.reduce((prevMaxId: string, currentNote: any) => {
      let res = prevMaxId;
      const currentId = currentNote.id;
      if (currentId !== "" && currentId > prevMaxId) {
        res = currentId;
      }
      return res;
    }, 0);
    return res;
  }

  shortText() {
    const maxLength = 5;
    let res = this.text;
    if (res.length > maxLength) {
      res = this.text.substring(0, maxLength);
      res = res.substring(0, Math.min(res.length, res.lastIndexOf(" ")));
    }
    return res;
  }

  nameDateTime() {
    let res = "";
    if (this.name !== "") {
      res += this.name;
    }
    res += (res !== "" ? ", " : "") + this.dateTime.toLocaleDateString();
    return res;
  }
}
