export default class SpeechRecognize {
  public recognition: any;
  public contentActive: boolean = false;
  public static totalCount: number = 0;

  constructor(
    options: Partial<{ lang: string; onresult: Function; onaudioend: Function }>
  ) {
    const lang = options.lang ?? "sr-RS";
    const onresult = options.onresult ?? "";
    const onaudioend =
      options.onaudioend ??
      (() => {
        console.log("Audio capturing ended");
      });
    let SpeechRecognition: any;
    if ("webkitSpeechRecognition" in window) {
      SpeechRecognition = window.webkitSpeechRecognition;
    } else if ("SpeechRecognition" in window) {
      SpeechRecognition = window.SpeechRecognition;
    }
    if (typeof SpeechRecognition !== "undefined") {
      console.log("speech recognition API supported");
      console.log("typeof SpeechRecognition", typeof SpeechRecognition);
      this.recognition = new SpeechRecognition();
      this.recognition.lang = lang;
      this.recognition.continuous = true;
      this.recognition.start();
      this.contentActive = true;
      if (onaudioend !== null) {
        this.recognition.onaudioend = onaudioend;
      }
      if (onresult !== null) {
        this.recognition.onresult = onresult;
      }
      this.recognition.onerror = (err: any) => {
        console.log("recognition.onerror", err);
      };
      // this.recognition.onsoundend = () => {
      //   console.log("recognition.onsoundend");
      // };
      // this.recognition.onend = () => {
      //   console.log("recognition.onend");
      // };

      SpeechRecognize.totalCount++;
      console.log("totalCount", SpeechRecognize.totalCount);
    } else {
      console.log("speech recognition API not supported");
    }
  }

  stopRecognition() {
    this.recognition.stop();
  }
}
