import React from "react";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import NoteSaveActivation from "./NoteSaveActivation";
// import NoteSave from "components/UI/SpeechRecognizeEdit/components/recognition/NoteSave";
import RecognizeSwitch from "./RecognizeSwitch";
// import NoteCurrent from "components/layout/NoteCurrent";
import { MOB_MAX_WIDTH } from "../../constants/constants";
import useRecognizeConnect from "../../providers/RecognizeConnect/RecognizeConnect";
import NavContentCopy from "./NavContentCopy";

const NavActive = () => {
  const { speechRecognize, speechRecognizeActive } = useRecognizeConnect();

  const newClickHandle = () => {
    // setRecognitionText("");
  };

  let viewTypeNav = null;
  viewTypeNav = (
    <div>
      <Button variant="outlined" onClick={newClickHandle}>
        NEW
      </Button>
    </div>
  );

  const content = (
    <div>
      <Div>
        <RecognizeSwitch />

        <DivCopyNav>
          <NavContentCopy />
        </DivCopyNav>
      </Div>
      <DivMobCurrentNote>{/* <NoteCurrent /> */}</DivMobCurrentNote>
    </div>
  );

  return content;
};

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const DivMobCurrentNote = styled.div`
  display: none;
  @media (max-width: ${MOB_MAX_WIDTH}) {
    display: block;
  }
`;

const DivCopyNav = styled.div`
  display: none;
  @media (max-width: ${MOB_MAX_WIDTH}) {
    display: block;
  }
`;

export default NavActive;
