// eslint-disable-next-line
import * as React from "react";
import RecognitionEdit from "./components/RecognitionEdit";
import NavActive from "./components/recognize_edit_navigation/NavActive";
import { Container } from "@mui/material";
import Navigation from "./components/recognize_edit_navigation/Navigation";
import styled from "@emotion/styled";
import { RecognizeConnectProvider } from "./providers/RecognizeConnect/RecognizeConnect";

type SpeechRecognizeEditProps = {
  initialText?: string;
  inputRef?: React.MutableRefObject<string>;
  onChange?: (text: string) => void;
};

const SpeechRecognizeEdit = ({
  initialText,
  inputRef,
  onChange,
}: SpeechRecognizeEditProps) => {
  return (
    <RecognizeConnectProvider
      initialText={initialText}
      inputRef={inputRef}
      onChange={onChange}
    >
      <Div>
        <DivPanel>
          <NavActive />
          <Container
            sx={{
              boxShadow: "2px 2px 2px #888888",
              minHeight: "35rem",
              backgroundColor: "rgba(200, 200, 200, 0.1)",
              paddingTop: "0.8rem",
              paddingBottom: "0.8rem",
            }}
          >
            <RecognitionEdit />
          </Container>
        </DivPanel>
        <DivAddNav>
          <Navigation />
        </DivAddNav>
      </Div>
    </RecognizeConnectProvider>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: row;
`;

const DivPanel = styled.div`
  flex-grow: 1;
`;

const DivAddNav = styled.div`
  margin-left: 1rem;
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

export default SpeechRecognizeEdit;
