import React from "react";
import styled from "@emotion/styled";
import { Card, CardContent, Typography } from "@mui/material";
import { VIEW_TYPE } from "constant/view_type";
import { useNoteNavigation } from "hooks/navigation/UseNoteNavigation";
import NoteSingleNav from "../NoteSingleNav";

function NoteItem({ note }: any) {
  const { noteNavigate } = useNoteNavigation();

  const clickNoteHandle = () => {
    noteNavigate(VIEW_TYPE.SINGLE, note);
  };

  const item = (
    <CardNote>
      <TypographyNameDate variant="h5" onClick={clickNoteHandle}>
        <span>{note.name}</span>,
        <SpanDate>{note.dateTime.toLocaleDateString()}</SpanDate>
      </TypographyNameDate>
      <CardContent>{note.shortText()}</CardContent>
      <NoteSingleNav note={note} />
    </CardNote>
  );
  return item;
}

const SpanDate = styled.span`
  margin-left: 1rem;
  font-size: 1rem;
`;

const TypographyNameDate = styled(Typography)`
  cursor: pointer;
`;

const CardNote = styled(Card)`
  padding: 1.5rem;
  margin-top: 1.5rem;
`;

export default NoteItem;
