import {
  NoteFormData,
  noteFormDefaultValuesGet,
} from "common/note/note_form_common";
import React from "react";
import { useForm } from "react-hook-form";
import { useIndexedDB } from "react-indexed-db";
import NoteForm from "../NoteForm/NoteForm";
import { useNoteAdd } from "hooks/diktafont/note/useNoteAdd";
import { QUERY_KEYS } from "constant/react_query";
import toast from "react-hot-toast";
import { useNoteNavigation } from "hooks/navigation/UseNoteNavigation";
import { VIEW_TYPE } from "constant/view_type";

function NoteNew() {
  const { handleSubmit, control } = useForm({
    defaultValues: noteFormDefaultValuesGet(),
  });
  const { noteAddMutate } = useNoteAdd({
    invalidateKeys: [QUERY_KEYS.NOTE_LIST_GET],
    successHandle: (id: number) => {
      console.log("add success", id);
      toast.success("Text added");
    },
  });
  const editorRef = React.useRef<string>("");
  const db = useIndexedDB("notes");

  const { noteNavigate } = useNoteNavigation();

  const cancelClickHandle = () => {
    noteNavigate(VIEW_TYPE.LIST);
  };

  const onSubmit = async (data: NoteFormData) => {
    const { name } = data;
    const text = editorRef.current;
    console.log("onSubmit - name, text", name, text);

    noteAddMutate({
      add: db.add,
      payload: { name, text },
    });
  };

  return (
    <div>
      <NoteForm
        control={control}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        editorRef={editorRef}
        editCancelClickHandle={cancelClickHandle}
      />
    </div>
  );
}

export default NoteNew;
