import * as React from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import SpeechRecognizeEdit from "components/UI/useDiktafontInput/SpeechRecognizeEdit";
import { defaultValueGet } from "common/parameters_common";
import InputField from "components/UI/InputField";

type NoteFormProps = {
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  defaultValues?: any;
  editorRef: React.MutableRefObject<string>;
  editCancelClickHandle?: Function;
};

const NoteForm = ({
  onSubmit,
  handleSubmit,
  defaultValues,
  control,
  editCancelClickHandle,
  editorRef,
}: NoteFormProps) => {
  const initialText = defaultValueGet(defaultValues, "text", "");
  const initialName = defaultValueGet(defaultValues, "name", "");

  return (
    <DivForm>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DivField>
          <SpeechRecognizeEdit initialText={initialText} inputRef={editorRef} />
        </DivField>
        <DivName>
          <InputField
            name="name"
            control={control}
            label="name"
            defaultValue={initialName}
          />
        </DivName>
        <DivNav>
          <Button type="submit">SAVE</Button>
          {editCancelClickHandle && (
            <ButtonCancel
              onClick={() => editCancelClickHandle()}
              variant="outlined"
            >
              Cancel
            </ButtonCancel>
          )}
        </DivNav>
      </Form>
    </DivForm>
  );
};

const DivForm = styled.div`
  margin-top: 0.75rem;
`;

const DivField = styled.div`
  margin-top: 1rem;
`;

const DivName = styled(DivField)`
  &&& input {
    padding-bottom: 0.5rem;
  }

  &&& fieldset {
    border: 0px;
    border-bottom: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 0px;
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const DivNav = styled.div`
  display: flex;
  margin-top: 1rem;
  div {
    text-align: right;
  }
`;

const ButtonCancel = styled(Button)`
  margin-left: 2rem;
`;

export default NoteForm;
