/* eslint-disable react/prop-types */
import React from "react";
import TextEdit from "./TextEdit";
import RecognitionText from "./RecognitionText";
import useRecognizeConnect from "../providers/RecognizeConnect/RecognizeConnect";

const RecognitionEdit = () => {
  const recognizeConnect = useRecognizeConnect();

  let content = null;
  content = <TextEdit />;
  // connecting.isTextEdit ? (
  //   <TextEdit />
  // ) : (
  //   <RecognitionText recognitionText={connecting.recognitionText} />
  // );
  content = <>{content}</>;

  return content;
};

export default RecognitionEdit;
