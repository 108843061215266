import {
  VIEW_TYPE,
  VIEW_TYPE_MODE,
  ViewType,
  ViewTypeMode,
} from "constant/view_type";
import RecognizeNote from "models/RecognizeNote";
import { useSetRecoilState } from "recoil";
import { viewTypeState } from "store/atoms/view_type";

type UseNoteNavigationResponse = {
  noteNavigate: (
    viewType: ViewType,
    note?: RecognizeNote,
    mode?: ViewTypeMode
  ) => void;
};

export function useNoteNavigation(): UseNoteNavigationResponse {
  const setViewType = useSetRecoilState(viewTypeState);

  const noteNavigate = (
    viewType: ViewType,
    note: RecognizeNote | null = null,
    mode: ViewTypeMode = VIEW_TYPE_MODE.READ
  ) => {
    if (viewType === VIEW_TYPE.LIST) {
      setViewType({
        type: VIEW_TYPE.LIST,
        note: new RecognizeNote(),
        mode: VIEW_TYPE_MODE.READ,
      });
    } else if (viewType === VIEW_TYPE.SINGLE) {
      if (note !== null) {
        const viewType = {
          type: VIEW_TYPE.SINGLE,
          note,
          mode: VIEW_TYPE_MODE.READ,
        };
        if (mode !== VIEW_TYPE_MODE.READ) {
          viewType.mode = mode;
        }
        setViewType(viewType);
      }
    }
  };

  return { noteNavigate };
}
