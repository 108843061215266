import RecognizeNote from "models/RecognizeNote";

export type NoteFormData = {
  name: string;
  text: string;
};

export const noteFormDefaultValuesGet = (
  note?: RecognizeNote
): NoteFormData => {
  if (note) {
    return {
      name: note.name,
      text: note.text,
    };
  } else {
    return {
      name: "",
      text: "",
    };
  }
};
