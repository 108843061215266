/* eslint-disable react/prop-types */
import React from "react";
import "./Navigation.css";
import Button from "@mui/material/Button";
import useRecognizeConnect from "../../providers/RecognizeConnect/RecognizeConnect";
import { inputFocusCursor } from "../../providers/RecognizeConnect/recognize_connect_common";
import NavContentCopy from "./NavContentCopy";

const NavBasic = () => {
  const { editTextRef } = useRecognizeConnect();

  const newRecordingClickHandle = () => {
    if (editTextRef !== null && editTextRef.current !== null) {
      editTextRef.current.value = "";
      inputFocusCursor(editTextRef);
    }
    // setRecognitionText("");
  };

  return (
    <div
      className="navigation-main-group"
      style={{ flexDirection: "column", marginTop: 0 }}
    >
      <div
        className="navigation-main-group"
        style={{ justifyContent: "space-between", marginTop: "0rem" }}
      >
        <NavContentCopy />
        <Button
          variant="contained"
          onClick={newRecordingClickHandle}
          sx={{ marginLeft: "1rem" }}
        >
          CLEAR
        </Button>
      </div>
    </div>
  );
};

export default NavBasic;
