export function obDefaultValGet(ob: any, key: string, defaultVal: any = "") {
  let val = defaultVal;
  if (ob !== null) {
    val = ob.hasOwnProperty(key) ? ob[key] : defaultVal;
  }
  return val;
}

export const envTypeDomainGet = (name: string) => {
  let envType = "";
  if (
    typeof process !== "undefined" &&
    typeof process.env !== "undefined" &&
    typeof process.env.NODE_ENV !== "undefined" &&
    process.env.NODE_ENV === "test"
  ) {
    envType = "test";
  } else {
    const loc = window.location.href + "";
    if (loc.includes("localhost")) {
      envType = "local";
    } else if (loc.includes("t." + name)) {
      envType = "local_address";
    } else if (loc.includes(name)) {
      envType = "production";
    }
  }
  return envType;
};

export const envTypeGet = () => {
  return envTypeDomainGet("diktafont");
};

export const apiUrlBaseGet = (versionIncluded = true) => {
  let url = "";
  const envType = envTypeGet();
  if (envType === "local") {
    url = "http://localhost:3001";
  } else if (envType === "local_address") {
    url = "http://localhost:3001";
  } else if (envType === "production") {
    url = "https://diktafont.emlearn.info";
  }

  if (versionIncluded) {
    url += "/api/v1";
  }

  return url;
};
