import React from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import RecognizeNote from "models/RecognizeNote";
import { VIEW_TYPE, VIEW_TYPE_MODE } from "constant/view_type";
import { useNoteNavigation } from "hooks/navigation/UseNoteNavigation";

function NavNoteNew() {
  const { noteNavigate } = useNoteNavigation();

  const newClickHandle = () => {
    noteNavigate(VIEW_TYPE.SINGLE, new RecognizeNote(), VIEW_TYPE_MODE.EDIT);
  };

  return (
    <ButtonNewNav variant="outlined" onClick={newClickHandle}>
      NEW
    </ButtonNewNav>
  );
}

const ButtonNewNav = styled(Button)`
  margin-left: 1rem;
`;

export default NavNoteNew;
