import React, { useState } from "react";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import "./App.css";
import MainContent from "./layout/MainContent";
import MainHeader from "./layout/MainHeader";
import { DBConfig } from "../indexeddb/DBConfig";
import { initDB } from "react-indexed-db";
import { envTypeGet, apiUrlBaseGet } from "../common/common";
import { ConfirmDialogProvider } from "common/modal/ConfirmDialog";

initDB(DBConfig);

console.log(
  "envTypeGet: ",
  envTypeGet(),
  "apiUrlBaseGet: ",
  apiUrlBaseGet(false)
);

fetch(apiUrlBaseGet(false) + "/api")
  .then((response) => {
    return response.text();
  })
  .then((result) => {
    console.log("api call result", result);
  })
  .catch((err) => {
    console.error("api call error");
  });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [diktafontNavigationAct, setDiktafontNavigationAct] = useState(false);

  const diktafontNavigationActToggle = () => {
    setDiktafontNavigationAct((currentValue) => !currentValue);
  };
  return (
    <ConfirmDialogProvider>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <Toaster />
          <div className="diktafont-main">
            <MainHeader
              diktafontNavigationActToggle={diktafontNavigationActToggle}
            />
            <div className="main-content-nav">
              <div className="diktafont-content">
                <MainContent />
              </div>
            </div>
          </div>{" "}
        </QueryClientProvider>
      </RecoilRoot>
    </ConfirmDialogProvider>
  );
}

export default App;
