import React from "react";
import {
  RecognizeConnectContextValue,
  RecognizeConnectProviderProps,
  SpeechText,
} from "./RecognizeConnect.type";
import SpeechRecognize from "../../models/SpeechRecognize";
import {
  fSetDefaultGet,
  inputFocusCursor,
  speechTextToEditTextRef,
} from "./recognize_connect_common";
import {
  onaudioendFunctionGet,
  onresultFunctionGet,
} from "../../speech_recognition/speech_recognition_common";

const RecognizeConnect = React.createContext<RecognizeConnectContextValue>({
  textLang: "",
  setTextLang: fSetDefaultGet<string>(),
  speechRecognize: null,
  setSpeechRecognize: fSetDefaultGet<SpeechRecognize | null>(),
  speechText: { text: "" },
  setSpeechText: fSetDefaultGet<SpeechText>(),
  editTextRef: null,
  onEditChange: fSetDefaultGet<string>(),
  speechRecognizeActive: false,
  setSpeechRecognizeActive: fSetDefaultGet<boolean>(),
});

export function RecognizeConnectProvider({
  children,
  initialText,
  inputRef,
  onChange,
}: RecognizeConnectProviderProps) {
  const [textLang, setTextLang] = React.useState<string>("lat");
  const [speechRecognize, setSpeechRecognize] =
    React.useState<SpeechRecognize | null>(null);
  const [speechText, setSpeechText] = React.useState<SpeechText>({ text: "" });

  const [inputText, setInputText] = React.useState<string>(initialText ?? "");

  const [speechRecognizeActive, setSpeechRecognizeActive] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    speechTextToEditTextRef(editTextRef, speechText, textLang);

    if (editTextRef !== null && editTextRef.current !== null) {
      setInputText(editTextRef.current.value);
    }
  }, [speechText]);

  let editTextRef = React.useRef<HTMLInputElement>(null);

  const onEditChange = React.useCallback(
    (text: string) => {
      setInputText(text);
    },
    [onChange]
  );

  React.useEffect(() => {
    if (
      initialText &&
      initialText !== "" &&
      editTextRef &&
      editTextRef.current
    ) {
      editTextRef.current.value = initialText;
    }
  }, [initialText]);

  React.useEffect(() => {
    if (onChange && editTextRef && editTextRef.current) {
      onChange(editTextRef.current.value);
    }
    if (editTextRef && editTextRef.current) {
      setInputText(editTextRef.current.value);
    }
  }, [onChange, editTextRef.current, editTextRef.current?.value, speechText]);

  React.useEffect(() => {
    // console.log("inputText", inputText);
    if (onChange) {
      onChange(inputText);
    }
    if (inputRef) {
      inputRef.current = inputText;
    }
  }, [inputText]);

  const startSpeechRecognition = React.useCallback(() => {
    const onresult = onresultFunctionGet(setSpeechText);
    const onaudioend = onaudioendFunctionGet(setSpeechRecognize);
    const speechRecogn = new SpeechRecognize({ onresult, onaudioend });
    speechRecogn.recognition.onend = () => {
      // console.log("speechRecogn recognition.onend");
      speechRecogn.recognition.start();
    };

    setSpeechRecognize(speechRecogn);

    inputFocusCursor(editTextRef);
  }, [speechRecognizeActive]);

  React.useEffect(() => {
    // console.log("effect speechRecognizeActive", speechRecognizeActive);

    if (!speechRecognizeActive) {
      if (
        speechRecognize !== null &&
        typeof speechRecognize?.recognition !== "undefined"
      ) {
        speechRecognize.stopRecognition();
        // console.log("stopRecognition");
        speechRecognize.recognition.onend = () => {};

        inputFocusCursor(editTextRef);
      }
      setSpeechRecognize(null);
    } else {
      startSpeechRecognition();
    }
  }, [speechRecognizeActive, startSpeechRecognition]);

  return (
    <RecognizeConnect.Provider
      value={{
        textLang,
        setTextLang,
        speechRecognize,
        setSpeechRecognize,
        speechText,
        setSpeechText,
        editTextRef,
        onEditChange,
        speechRecognizeActive,
        setSpeechRecognizeActive,
      }}
    >
      {children}
    </RecognizeConnect.Provider>
  );
}

export default function useRecognizeConnect() {
  return React.useContext(RecognizeConnect);
}
