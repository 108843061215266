import RecognizeNote from "models/RecognizeNote";

export type NoteSearchParams = {
  getAll: <T = any>() => Promise<T[]>;
  payload?: {
    skip?: number;
    limit?: number;
  };
};

const noteResponseFormat = (note: any): any => {
  const result = { ...note };

  return result;
};

export const noteGet = async (slug: string): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    // response = await axiosApkores.get(`note/${slug}`);
    response = "";
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const noteSearch = async ({
  getAll,
  payload,
}: NoteSearchParams): Promise<any> => {
  let items = await getAll();
  items = items.map((item: any) => new RecognizeNote(item));
  return items;
};
