import React from "react";
import { FSet, SpeechText } from "./RecognizeConnect.type";
import { textLangConvert } from "../../speech_recognition/lang_conversion";

export function fSetDefaultGet<T>(): FSet<T> {
  return (t: T) => {};
}

export function speechTextToEditTextRef(
  editTextRef: React.RefObject<HTMLInputElement>,
  speechText: SpeechText,
  textLang: string
) {
  if (editTextRef !== null && editTextRef.current !== null) {
    const text = editTextRef.current.value;
    const selectionStart = editTextRef.current.selectionStart;
    if (selectionStart !== null) {
      const textPrefix = text.slice(0, selectionStart);
      const textSufix = text.slice(selectionStart);
      const addText = " " + textLangConvert(speechText.text, textLang) + " ";
      const textNew = textPrefix + addText + textSufix;
      editTextRef.current.value = textNew;
      editTextRef.current.focus();
      editTextRef.current.selectionEnd = selectionStart + addText.length;
    }
  }
}

export function inputFocusCursor(
  editTextRef: React.RefObject<HTMLInputElement> | null
) {
  if (editTextRef !== null && editTextRef.current !== null) {
    editTextRef.current.focus();
    const selectionStart = editTextRef.current.selectionStart;
    if (selectionStart !== null) {
      editTextRef.current.selectionEnd = selectionStart;
    }
  }
}
