import React from "react";
import { useRecoilValue } from "recoil";
import { isEditState, isNewNoteState } from "store/selectors/view_type";
import NoteNew from "../NoteNew";
import NoteEdit from "../NoteEdit";
import NoteRead from "../NoteRead";
import { viewTypeState } from "store/atoms/view_type";

function NoteSingle() {
  const isNewNote = useRecoilValue(isNewNoteState);
  const isEdit = useRecoilValue(isEditState);
  const viewType = useRecoilValue(viewTypeState);

  console.log("NoteSingle viewType", viewType);

  return (
    <>
      {isNewNote && <NoteNew />}
      {isEdit && !isNewNote && <NoteEdit />}
      {!isEdit && <NoteRead />}
    </>
  );
}

export default NoteSingle;
