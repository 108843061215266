import SpeechRecognize from "../models/SpeechRecognize";
import { langTextGet } from "./lang_conversion";

export const recognitonTextGet = (
  text: string,
  textLang: string = "latr",
  recognitionText: string = ""
) => {
  let res = langTextGet(text, textLang);
  res = res.toLowerCase();
  let recognText = recognitionText.trim();
  if (recognText.substring(recognText.length - 1) === ".") {
    res = res.charAt(0) + res.charAt(1).toUpperCase() + res.substring(2);
  }

  return res;
};

export const onresultFunctionGet =
  (setSpeechText: Function) => (event: Required<{ results: any[] }>) => {
    // console.log("Audio capturing result event");
    const speechTxt = event.results[event.results.length - 1][0].transcript;
    // console.log("Audio capturing result event - text", speechTxt);
    setSpeechText({ text: speechTxt });
  };

export const onaudioendFunctionGet =
  (setSpeechRecognize: Function) => (event: any) => {
    console.log("onaudioend - totalCount", SpeechRecognize.totalCount);
    // setSpeechRecognize(null);
  };
