import {
  NoteFormData,
  noteFormDefaultValuesGet,
} from "common/note/note_form_common";
import React from "react";
import { useForm } from "react-hook-form";
import { useIndexedDB } from "react-indexed-db";
import NoteForm from "../NoteForm/NoteForm";
import { useNoteAdd } from "hooks/diktafont/note/useNoteAdd";
import { QUERY_KEYS } from "constant/react_query";
import toast from "react-hot-toast";
import { useRecoilValue } from "recoil";
import { noteState } from "store/selectors/view_type";
import { useNoteUpdate } from "hooks/diktafont/note/useNoteUpdate";
import { useNoteNavigation } from "hooks/navigation/UseNoteNavigation";
import { VIEW_TYPE, VIEW_TYPE_MODE } from "constant/view_type";

function NoteEdit() {
  const note = useRecoilValue(noteState);
  const { noteNavigate } = useNoteNavigation();

  React.useEffect(() => {
    console.log("note edit", note);
  }, [note]);

  const { handleSubmit, control } = useForm({
    defaultValues: noteFormDefaultValuesGet(note),
  });
  const { noteUpdateMutate } = useNoteUpdate({
    invalidateKeys: [QUERY_KEYS.NOTE_LIST_GET],
    successHandle: (id: number) => {
      console.log("update success", id);
      toast.success("Text updated");
    },
  });
  const editorRef = React.useRef<string>("");
  const db = useIndexedDB("notes");

  const cancelClickHandle = () => {
    noteNavigate(VIEW_TYPE.SINGLE, note, VIEW_TYPE_MODE.READ);
  };

  const onSubmit = async (data: NoteFormData) => {
    const { name } = data;
    const text = editorRef.current;
    console.log("onSubmit - name, text", name, text);

    noteUpdateMutate({
      update: db.update,
      payload: { id: note.id as number, name, text },
    });
  };

  return (
    <div>
      <NoteForm
        control={control}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        editorRef={editorRef}
        defaultValues={noteFormDefaultValuesGet(note)}
        editCancelClickHandle={cancelClickHandle}
      />
    </div>
  );
}

export default NoteEdit;
