import { useMutation } from "@tanstack/react-query";
import {
  noteAdd,
  NoteAddParams,
} from "common/api/diktafont/diktafont_note_edit";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseNoteAddParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useNoteAdd = (hookParams?: UseNoteAddParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: noteAddMutate } = useMutation({
    mutationFn: async (noteAddParams: NoteAddParams) =>
      await noteAdd(noteAddParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { noteAddMutate };
};
