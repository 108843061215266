import React, { useRef, useState } from "react";
import "./Navigation.css";
import Button from "@mui/material/Button";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import useRecognizeConnect from "../../providers/RecognizeConnect/RecognizeConnect";
import {
  convertLangDefaultGet,
  textLangConvert,
} from "../../speech_recognition/lang_conversion";

const NavLang = () => {
  const { textLang, setTextLang } = useRecognizeConnect();
  // eslint-disable-next-line
  const [convertDefaultLang, setConvertDefaultLang] = useState(
    convertLangDefaultGet(textLang)
  );
  const convertLangRef = useRef<{ value: string }>();

  const handleLangChange = (ev: SelectChangeEvent<string>) => {
    const textLangValue = ev.target.value;
    console.log("handleLangChange", textLangValue);
    setTextLang(textLangValue);
  };

  const convertClickHandle = () => {
    const convertLang =
      typeof convertLangRef.current !== "undefined"
        ? convertLangRef.current.value
        : "";
    // let textConverted = textLangConvert(recognitionText, convertLang);
    // if (textConverted !== recognitionText) {
    //   setRecognitionText(textConverted);
    // }
    console.log("convertClickHandle", convertLang);
  };

  return (
    <>
      <div className="navigation-main-group">
        <FormControl fullWidth>
          <InputLabel id="text_lang_label">Language:</InputLabel>
          <Select
            labelId="text_lang_label"
            id="text_lang"
            value={textLang}
            label="Language"
            onChange={handleLangChange}
          >
            <MenuItem value="cir">cir</MenuItem>
            <MenuItem value="lat">lat</MenuItem>
            <MenuItem value="en-small">small en</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="navigation-main-group">
        <FormControl fullWidth>
          <InputLabel id="text_convert_label">Convert to:</InputLabel>
          <Select
            labelId="text_convert_label"
            id="text_convert"
            defaultValue={convertDefaultLang}
            label="Convert"
            inputRef={convertLangRef}
            sx={{ marginTop: "0.5rem" }}
          >
            <MenuItem value="cir">cir</MenuItem>
            <MenuItem value="lat">lat</MenuItem>
            <MenuItem value="en">en</MenuItem>
            <MenuItem value="small en">small en</MenuItem>
          </Select>
          <Button
            variant="outlined"
            onClick={convertClickHandle}
            sx={{ marginTop: "0.5rem", width: "2rem" }}
          >
            OK
          </Button>
        </FormControl>
      </div>
    </>
  );
};

export default NavLang;
