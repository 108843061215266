import { useNoteSearch } from "hooks/diktafont/note/useNoteSearch";
import { useIndexedDB } from "react-indexed-db";
import NoteItem from "./NoteItem";

const NoteList = () => {
  const db = useIndexedDB("notes");

  const noteListResult = useNoteSearch({ getAll: db.getAll });

  return (
    <>
      {noteListResult &&
        noteListResult.map((note: any, ind: number) => (
          <NoteItem note={note} key={ind} />
        ))}
    </>
  );
};

export default NoteList;
