import React from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { VIEW_TYPE } from "constant/view_type";
import { useNoteNavigation } from "hooks/navigation/UseNoteNavigation";

function NavNoteList() {
  const { noteNavigate } = useNoteNavigation();

  const listClickHandle = () => {
    noteNavigate(VIEW_TYPE.LIST);
  };

  return (
    <ButtonListNav variant="outlined" onClick={listClickHandle}>
      LIST
    </ButtonListNav>
  );
}

const ButtonListNav = styled(Button)`
  margin-left: 1rem;
`;

export default NavNoteList;
