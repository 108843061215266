import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { noteState } from "store/selectors/view_type";
import NoteSingleNav from "../NoteSingleNav";

function NoteRead() {
  const note = useRecoilValue(noteState);

  return (
    <Div>
      <h2>{note.name}</h2>
      <DivDate>{note.dateTime.toLocaleDateString()}</DivDate>
      <DivNoteText>{note.text}</DivNoteText>
      <NoteSingleNav note={note} />
    </Div>
  );
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivDate = styled.div`
  color: rgba(0, 0, 0, 0.87);
`;

const DivNoteText = styled.div`
  margin-top: 1rem;
`;

export default NoteRead;
