/* eslint-disable react/prop-types */
import React from "react";
import { Button, TextField } from "@mui/material";
import styled from "@emotion/styled";
import useRecognizeConnect from "../providers/RecognizeConnect/RecognizeConnect";

const TextEdit = () => {
  const { editTextRef, onEditChange } = useRecognizeConnect();

  const changeHandle = () => {
    if (editTextRef && editTextRef.current) {
      onEditChange(editTextRef.current.value);
    }
  };

  const okClickHandle = () => {
    if (editTextRef !== null && editTextRef.current !== null) {
      const text = editTextRef.current.value;
      const selectionStart = editTextRef.current.selectionStart;
      if (selectionStart !== null) {
        const textPrefix = text.slice(0, selectionStart);
        const textSufix = text.slice(selectionStart);
        const addText = " AAA ";
        const textNew = textPrefix + addText + textSufix;
        editTextRef.current.value = textNew;
        editTextRef.current.focus();
        editTextRef.current.selectionEnd = selectionStart + addText.length;
      }
      console.log("okClickHandle", text);
    }
  };

  const cancelClickHandle = () => {
    // setActionEdit({ ...ACTION_EDIT_DEFAULT, textEdit: false });
  };

  let content = (
    <Div>
      <TextField
        multiline
        defaultValue={""}
        sx={{ height: "100%" }}
        fullWidth
        minRows={20}
        inputRef={editTextRef}
        onChange={changeHandle}
      />
      {/* <Button
        variant="outlined"
        onClick={okClickHandle}
        sx={{ marginLeft: "0rem", marginTop: "1rem", marginBottom: "1rem" }}
      >
        OK
      </Button>
      <Button
        variant="text"
        onClick={cancelClickHandle}
        sx={{ marginLeft: "1rem", marginTop: "1rem", marginBottom: "1rem" }}
      >
        Cancel
      </Button> */}
    </Div>
  );

  return content;
};

const Div = styled.div`
  min-height: 100%;
  div {
    min-height: 100%;
  }
`;

export default TextEdit;
