import { selector } from "recoil";
import { viewTypeState } from "../atoms/view_type";
import { VIEW_TYPE, VIEW_TYPE_MODE } from "constant/view_type";

export const isSingleViewState = selector({
  key: "isSingleViewState",
  get: ({ get }) => {
    const viewType = get(viewTypeState);
    const check = viewType.type === VIEW_TYPE.SINGLE;
    return check;
  },
});

export const isListViewState = selector({
  key: "isListViewState",
  get: ({ get }) => {
    const viewType = get(viewTypeState);
    const check = viewType.type === VIEW_TYPE.LIST;
    return check;
  },
});

export const noteIdState = selector({
  key: "noteIdState",
  get: ({ get }) => {
    const viewType = get(viewTypeState);
    const res = viewType.note.id;
    return res;
  },
});

export const noteState = selector({
  key: "noteState",
  get: ({ get }) => {
    const viewType = get(viewTypeState);
    const res = viewType.note;
    return res;
  },
});

export const isNewNoteState = selector({
  key: "isNewNoteState",
  get: ({ get }) => {
    const viewType = get(viewTypeState);
    const res =
      viewType.mode === VIEW_TYPE_MODE.EDIT && viewType.note.id === "";
    return res;
  },
});

export const isEditState = selector({
  key: "isEditState",
  get: ({ get }) => {
    const viewType = get(viewTypeState);
    const res = viewType.mode === VIEW_TYPE_MODE.EDIT;
    return res;
  },
});
