/* eslint-disable react/prop-types */
import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import useRecognizeConnect from "../../providers/RecognizeConnect/RecognizeConnect";

const RecognizeSwitch = () => {
  const { speechRecognizeActive, setSpeechRecognizeActive } =
    useRecognizeConnect();

  const switchLabel = React.useMemo(
    () => (!speechRecognizeActive ? "START" : "STOP"),
    [speechRecognizeActive]
  );

  const actionSwitchChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const recognitionActive = event.target.checked;
    setSpeechRecognizeActive(recognitionActive);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          onChange={actionSwitchChangeHandler}
          checked={speechRecognizeActive}
        />
      }
      label={switchLabel}
    />
  );
};

export default RecognizeSwitch;
