/* eslint-disable react/prop-types */
import React from "react";
import Button from "@mui/material/Button";
import useRecognizeConnect from "../../providers/RecognizeConnect/RecognizeConnect";
import { copyToClipboard } from "../../speech_recognition/clipboard_common";

const NavContentCopy = () => {
  const { editTextRef } = useRecognizeConnect();

  const copyRecordingClickHandle = () => {
    if (editTextRef !== null && editTextRef.current !== null) {
      copyToClipboard(editTextRef.current.value);
    }
  };

  return (
    <Button variant="contained" onClick={copyRecordingClickHandle}>
      COPY
    </Button>
  );
};

export default NavContentCopy;
