import { useMutation } from "@tanstack/react-query";
import {
  noteUpdate,
  NoteUpdateParams,
} from "common/api/diktafont/diktafont_note_edit";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseNoteUpdateParams = {
  invalidateKeys?: string[];
  successHandle?: Function;
};

export const useNoteUpdate = (hookParams?: UseNoteUpdateParams) => {
  const { invalidateQueriesByKeys } = useInvalidateQueryByKeys();
  const { mutate: noteUpdateMutate } = useMutation({
    mutationFn: async (noteUpdateParams: NoteUpdateParams) =>
      await noteUpdate(noteUpdateParams),
    onSuccess: (response) => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      }

      if (hookParams?.successHandle) {
        hookParams.successHandle(response);
      }
    },
  });

  return { noteUpdateMutate };
};
